import React, { Component } from 'react'
import '../../styles/main/secondView.css'
import logo from '../../logoLetrasBlanco.svg'
import flecha from '../../icons8-abajo-48.png'
import { Link } from 'react-scroll'

export default class secondView extends Component {
    render() {
        window.addEventListener('scroll', function () {
            let animationCard = this.document.getElementById('card1');
            let animationCard2 = this.document.getElementById('card2');
            let animationCard3 = this.document.getElementById('card3');
            let posicionCard = animationCard.getBoundingClientRect().top;
            let posicionCard2 = animationCard2.getBoundingClientRect().top;
            let posicionCard3 = animationCard3.getBoundingClientRect().top;

            //console.log(posicionCard);
            let tamañoPantalla = (this.window.innerHeight / 4) * 3;

            if (posicionCard < tamañoPantalla) {
                //console.log(true);
                animationCard.style.animation = 'cardMover 1s ease-out';
            }
            if (posicionCard2 < tamañoPantalla) {
                //console.log(true);
                animationCard2.style.animation = 'cardMover 1s ease-out';
            }
            if (posicionCard3 < tamañoPantalla) {
                //console.log(true);
                animationCard3.style.animation = 'cardMover 1s ease-out';
            }
        });
        return (
            <div className="containerSeconView" id='service' ref={this.testRef}>
                <div className="containerCard">
                    <div className="top">
                        <img src={flecha} className='logoFlecha' alt='Logo' />
                        <img src={logo} className='logoLetras' alt='Logo' />
                        <img src={flecha} className='logoFlecha' alt='Logo' />
                    </div>
                    <div className="cards" >
                        <div className="cardServices" id='card1'>
                            <img src='https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80' className='imgCard' alt='Logo' />
                            <h2 className='titleCard'>Application Web</h2>
                            <p className='parrafoCard'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, quisquam saepe ut officiis necessitatibus vero fugiat nemo ipsam unde sequi quidem nesciunt impedit eos laboriosam blanditiis cumque error! Fugiat, fugit!</p>
                            <div className='containerCardBtn'>
                                <Link className='cardBtnText' to={'contactUs'} spy={true} smooth={true}>I want it</Link>
                            </div>
                        </div>
                        <div className="cardServices" id='card2'>
                            <img src='https://images.unsplash.com/photo-1555949963-aa79dcee981c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80' className='imgCard' alt='Logo' />
                            <h2 className='titleCard'>API Backend</h2>
                            <p className='parrafoCard'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, quisquam saepe ut officiis necessitatibus vero fugiat nemo ipsam unde sequi quidem nesciunt impedit eos laboriosam blanditiis cumque error! Fugiat, fugit!</p>
                            <div className='containerCardBtn'>
                                <Link className='cardBtnText' to={'contactUs'} spy={true} smooth={true}>I want it</Link>
                            </div>
                        
                        </div>
                        <div className="cardServices" id='card3'>
                            <img src='https://images.unsplash.com/photo-1519222970733-f546218fa6d7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80' className='imgCard' alt='Logo' />
                            <h2 className='titleCard'>Portfolio Web</h2>
                            <p className='parrafoCard'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, quisquam saepe ut officiis necessitatibus vero fugiat nemo ipsam unde sequi quidem nesciunt impedit eos laboriosam blanditiis cumque error! Fugiat, fugit!</p>
                            <div className='containerCardBtn'>
                                <Link className='cardBtnText' to={'contactUs'} spy={true} smooth={true}>I want it</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
