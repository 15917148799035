import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';


const ProtectedRoutes = () => {
    var resBool = false;
    if (!localStorage.getItem("token")) {  
        console.log('not access');
    } else {
        resBool = true
    }
    return resBool ? <Outlet /> : <Navigate to={"/login"} />
}



export default ProtectedRoutes;
