import React, { Component } from 'react'

import Navigation from '../navigation'
import FirstView from './firstView'
import Footer from '../footer'
import SecondView from './secondView';
import ThirdView from './thirdView';
import FourthView from './fourthView';

export default class mainView extends Component {
    render() {
        return (
            <div>
                <Navigation />
                <FirstView />
                <SecondView />
                <ThirdView/>
                <FourthView/>
                <Footer />
            </div>
        )
    }
}
