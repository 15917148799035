import React, { Component } from 'react'
import '../styles/footer.css'
import logo from '../logoLetrasBlanco.svg'

export default class footer extends Component {
    render() {
        return (
            <div className='footerContainer'>
                <div className='footerItem'>
                    <img src={logo} alt='Logo' height='60px' />
                </div>
                <div className='footerItem'>
                    <small className="ml-2">&copy; Zettha LLC, 2022. All rights reserved.</small>
                </div>
                <div className='footerItem'>
                    <div className='networkFooter'>
                        <a href="https://www.instagram.com/zettha_llc/">
                        <img src="https://cdn-icons-png.flaticon.com/512/174/174855.png" alt="Instagram" height={'45px'} />
                        </a>
                    </div>
                    <div className='networkFooter'>
                        <a href="https://twitter.com/zetthaLLC">
                        <img src="https://cdn.icon-icons.com/icons2/1584/PNG/512/3721677-twitter_108058.png" alt="Twitter" height={'45px'} />
                        </a>
                    </div>
                    <div className='networkFooter' >
                        <a href="https://www.instagram.com/zettha_llc/">
                        <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" height={'45px'} />
                        </a>
                    </div>
                    <div className='networkFooter' >
                        <a href="/login">
                        <img src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png" alt="LinkedIn" height={'45px'} />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
