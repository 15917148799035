import React, { Component } from 'react'
import ilustration from '../../images/sapiens.svg'
import {Link} from 'react-scroll'

import '../../styles/main/firstView.css'

export default class firstView extends Component {
  render() {
    return (
      <div id='init' className='firstViewContainer'>
        <div className="subContainerFirstView">
          <div className='firstViewText'>Creamos tu presencia <br/>en el mundo digital<br/><div className='segundoTexto'>"Una applicacion a la vez"</div></div>
          <Link className='buttonFirst' to={"contactUs"} spy={true} smooth={true}>Contact Us</Link>
        </div>
        <div className="subContainerFirstView">
          <img src={ilustration} alt="Logo" className='ilustration'/>
        </div>
      </div>
    )
  }
}
