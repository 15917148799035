/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useGeolocated } from "react-geolocated";
import '../../styles/main/fourthView.css'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function FourthView() {
  const { coords, isGeolocationAvailable } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  console.log(coords);

  const notifySuccess = (i) => {
    toast.success('Hemos recibido tu mensaje con exito!!!', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const notifyError = (i) => {
    toast.error('Ha ocurrido un error, Vuelve a intentar', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const services = [{ value: "applicationWeb", label: "Application Web" },
  { value: "apiBackend", label: "API Backend" },
  { value: "portfolioWeb", label: "Portfolio Web" }];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [service, setService] = useState(services[0]);
  useEffect(() => {
    //console.log(service)
  }, []);

  function handleChange(value) {
    setService(value);
    // handle other stuff like persisting to store etc
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    var regExpCorreo = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var isEmail = regExpCorreo.exec(email);
    if (isEmail) {
      console.log("funciona");
      try {
        var res;
        if (isGeolocationAvailable) {
          res = await fetch(process.env.REACT_APP_URL_SERVER+"message", {
            method: "POST",
            body: JSON.stringify({
              name: name,
              email: email,
              phone: phone,
              description: description,
              service: service,
              coordinate: coords
            }),
          });
        } else {
          res = await fetch(process.env.REACT_APP_URL_SERVER+"message", {
            method: "POST",
            body: JSON.stringify({
              name: name,
              email: email,
              phone: phone,
              description: description,
              service: service
            }),
          });
        }
        let resJson = await res.json();
        console.log(resJson);
        if (res.status === 200) {
          setName("");
          setEmail("");
          setPhone("");
          setDescription("");
          setService(services[0]);
          notifySuccess();
        } else {
          notifyError();
        }
      } catch (err) {
        console.log(err);
        notifyError();
      }
    } else {
      console.log("no funciona");

    }
  };

  return (
    <div id='contactUs' className="fourthContainer">
      <div className="subContainer">
        <form className='infoContainer' onSubmit={handleSubmit}>
          <div>Contact Us</div>
          <input type="text" className='fourthContainerData' name="Name" placeholder="Your Name" value={name} onChange={(i) => setName(i.target.value)} />
          <input type="email" className='fourthContainerData' name="Email" placeholder="Your Email" value={email} onChange={(i) => setEmail(i.target.value)} />
          <input type="text" className='fourthContainerData' name="Phone" placeholder="Your Phone" value={phone} onChange={(i) => setPhone(i.target.value)} />
          <Select
            className="fourthContainerSelect"
            onChange={(i) => handleChange(i)}
            options={services}
            defaultValue={service} />
          <textarea className='fourthContainerParrag' placeholder='Message' value={description} onChange={(i) => setDescription(i.target.value)} />
          <input type="submit" className='fourthContainerButton' value="Submit" />
          <ToastContainer />
        </form>
      </div>
    </div>
  )
}
