
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainView from './components/main/mainView'
import Dashboard from './components/dashboard/mainDashboard';
import Login from './components/user/login';
import ProtectedRoutes from './protectedRoute';

import { Provider } from 'react-redux';
import store from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

function App() {

  let persistor = persistStore(store);


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route path="/" element={<MainView />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
