import {createSlice} from '@reduxjs/toolkit';

import axios from 'axios';

export const userSlice = createSlice({
    name: "users",
    initialState:{
        userAuth:{}
    },
    reducers:{
        setUsers: (state = {}, action) => {
            state.userAuth = action.payload;
        },
        /*setToken: (state, action) => {
            state.token = action.payload;
        },
        setExp: (state, action) => {
            state.timeExp = action.payload;
        }*/
    }
})

export const { setUsers } = userSlice.actions;

export default userSlice.reducer;


export const fetchUser = () => (dispatch) => {

    axios.get('https://reqres.in/api/users/2')
    .then((response) => {
        dispatch(setUsers(response.data.data));
    })
    .catch((error) => {console.log(error)});

}