import React, { Component } from 'react'
import logo from '../logo_sfondo.svg'
import {Link} from 'react-scroll'

import '../styles/navigation.css'

export default class navigation extends Component {
  render() {
    return (
      <div className='navigationContainer'>
          <div className='navigationItem'>
            <Link className='navigationLink' to={'init'} spy={true} smooth={true}>Inicio</Link>
          </div>
          <div className='navigationItem'>
            <Link className='navigationLink' to={'service'} spy={true} smooth={true}>Servicios</Link>
          </div>
            <Link className='navigationLogo' to={'/'}> 
              <img src={logo} alt="Logo"/>
            </Link>
          <div className='navigationItem'>
            <Link className='navigationLink' to={'aboutUs'} spy={true} smooth={true}>Nosotros</Link>
          </div>
          <div className='navigationItem'>
            <Link className='navigationLink' to={'contactUs'} spy={true} smooth={true}>Cantactenos</Link>
          </div>
      </div>
    )
  }
}
