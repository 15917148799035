import React, { Component } from 'react'
import '../../styles/main/thirdView.css'
import mission from '../../images/icons8-mission-100.png'
import vission from '../../images/icons8-vision-96.png'
import ourValue from '../../images/icons8-developer-mode-80.png'

export default class thirdView extends Component {
  render() {
    return (
      <div id='aboutUs' className='thirdViewContainer'>
        <div className="titleContainer">
          <div>About us</div>
        </div>
        <div className="dataContainer">
          <div className="dataCard">
            <div className="dataItem itemFront">
              <div>Our Mission</div>
              <img src={mission} alt="Mission" />
            </div>
            <div className="dataItem itemBack">
              <div><img src={mission} alt="Mission" /></div>
              <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit soluta nulla quo recusandae natus? Voluptatem.</div>
            </div>
          </div>
          <div className="dataCard">
            <div className="dataItem itemFront">
              <div>Our Vission</div>
              <img src={vission} alt="Vission" />
            </div>
            <div className="dataItem itemBack">
              <div><img src={vission} alt="" /></div>
              <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit soluta nulla quo recusandae natus? Voluptatem.</div>
            </div>
          </div>
          <div className="dataCard">
          <div className="dataItem itemFront">
              <div>Our Value</div>
              <img src={ourValue} alt="Value" />
            </div>
            <div className="dataItem itemBack">
              <div><img src={ourValue} alt="" /></div>
              <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit soluta nulla quo recusandae natus? Voluptatem.</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
