import {configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

//slices
import users from './slices/auth'

const reducers = combineReducers({
 users         
});

const persistConfig = {
    key: 'rootZettha',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;


/*export default configureStore({
    reducer: {
        users
    }
})*/