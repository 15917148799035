import React, { useState } from 'react'
import Footer from '../footer'
import '../../styles/user/login.css'
import logo from '../../logo_sfondo.svg'
import logoLetras from '../../logoLetras_sfondo.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios'


import { fetchUser, setUsers } from '../../store/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'
import { Alert, Collapse, IconButton } from '@mui/material';
import axios from 'axios'
import { red } from '@mui/material/colors'

export default function Login() {

    const { userAuth: usuario } = useSelector(state => state.users);


    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        //dispatch(fetchUser());
    }, [dispatch])


    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    let handleSubmitLogin = async (e) => {
        e.preventDefault();
        try {
            Axios({
                method: "POST",
                data: {
                    email: user,
                    password: password,
                },
                withCredentials: true,
                url: process.env.REACT_APP_URL_SERVER+"users/login",
            }).then((res) => {
                console.log(res)
                if (!res.data.status) {
                    setAlertContent(res.data.data);
                    setAlert(true);
                } else {
                    const configHeader = {
                        'Content-Type': 'application/json',
                        'Authorization': res.data.data
                    }
                    axios({
                        method: "GET",
                        withCredentials: true,
                        headers: configHeader,
                        url: process.env.REACT_APP_URL_SERVER + "users/profile?secret_token=" + res.data.data,
                    }).then((response) => {
                        console.log(response);
                        dispatch(setUsers(response.data.user));
                        localStorage.setItem("token",response.data.token);
                        console.log(localStorage.getItem("token"));
                        navigate("/dashboard");

                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => { });
        } catch (e) {
            console.log(e);
        }

    }
    const alertComponent = (<Alert severity='error' onClose={() => { setAlert(false) }}>{alertContent}</Alert>);

    return (
        <div>
            <div className="soloLogo">
                <a href="/">
                    <img src={logo} alt="" />
                </a>
            </div>
            <div className='loginContainer'>
                <div className='firstColumn'>
                    <img src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80" alt="" className='imgColumn' />
                </div>
                <div className='formColumn'>
                    <form onSubmit={handleSubmitLogin} className='formLogin'>
                        <img src={logoLetras} alt="" />
                        <h3>Login</h3>
                        {/*alert ? <Alert severity="error">Usuario o Contraseña estan erroneos</Alert>: <></> */}
                        <Collapse in={alert}>{alertComponent}</Collapse>
                        <input type="text" placeholder='User' name="User" value={user} onChange={(i) => setUser(i.target.value)} />
                        <input type="password" placeholder='Password' name="Password" value={password} onChange={(i) => setPassword(i.target.value)} />
                        <input type="submit" value="Log in" className='formBtnLogin' />
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
