import React, { useState } from 'react'
import Footer from '../footer'
import '../../styles/dashboard/dashboard.css'
import logo from '../../logo_sfondo.svg'
import logoLetras from '../../logoLetras_sfondo.svg'
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader";


import { fetchUser, setUsers, setToken, setExp } from '../../store/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'
import { Alert, Collapse, IconButton } from '@mui/material';
import axios from 'axios'


export default function MainDashboard() {

  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [filename, setFileName] = useState("");

  const navigate = useNavigate();

  const tasks = useSelector(state => state.users);


  let handleSubmitLogout = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem("token")
    dispatch(setUsers());
    navigate("/login");
    } catch (err) {
      console.log(err)
    }
  }
  function resetUser() {
    localStorage.removeItem("token")
    dispatch(setUsers());
    navigate("/login");
  }
  let handleUploadImgLogin = async (e) => {
    e.preventDefault();

    
    const formData = new FormData(e.target);

    try {
      /*console.log(process.env);
      for (var pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }*/
      await Axios({
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: formData,
        url: process.env.REACT_APP_URL_SERVER + "message/fileLogin",
      }).then((res) => {
        if (!res.data.isToken) {
          resetUser();
        }
        console.log(res)
      }).catch((error) => {
        console.log(error.response.data.isToken);
        if (!error.response.data.isToken) {
          resetUser();
        }
      });
    } catch (e) {
      
      console.log(e);
    }
  }
  const onChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }
  return (
    <div>
      {localStorage.getItem("token")?
        <div className='cardDashboard'>
          <h3>Formulario para cambiar foto del login</h3>
          <form onSubmit={handleUploadImgLogin} encType="multipart/form-data" className='sendFileDashboard'>
            <input type="file" name="avatar" accept='.png' onChange={onChange} />
            <input type="submit" value="Submit" />
          </form>
          <form onSubmit={handleSubmitLogout} className='formLogin btnDashboard'>
            <input type="submit" value="Logout" className='formBtnLogin' />
          </form>
        </div>
        : 
        <ClipLoader
          color={"#000000"}
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      }
    </div>

  )
}



